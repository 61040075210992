@media (min-width: 1024px) {
  .only-mobile {
    display: none;
  }

  .main > .flexible {
    width: 80%;
    margin: 0 auto;
  }

  .flex-center-pc {
    align-items: center;
  }

  .main-details > h2 {
    font-size: 70px;
    line-height: 1.2;
  }

  .main-details > h1 {
    font-size: 70px;
  }

  .main-details > p {
    font-size: 22px;
  }

  .buttons {
    flex-direction: row;
    padding: 70px 0 60px;
    margin: -8px;
  }

  .splash {
    width: 360px;
    height: 750px;
    overflow: hidden;
    background: #fff;
    border-radius: 36px;
    margin: 20px;
    box-shadow: 0 0 10px 5px #888;
  }

  .button {
    width: 220px;
    line-height: 68px;
    font-size: 18px;
  }

  .splash > img {
  }

  .intro > h3 {
    margin-top: 50px;
    font-size: 26px;
  }

  .intro > h2 {
    font-size: 60px;
    margin-bottom: 14px;
  }

  .intro > p {
    font-size: 22px;
  }

  .service > h2 {
    font-size: 70px;
    padding: 136px 0 0 235px;
  }

  .image-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 133px;
    margin-top: 40px;
  }
  .image-wrapper > img {
    width: 1099px;
  }

  .tip {
    width: auto;
    height: 140px;
  }

  .tip > p {
    margin: 0 -3px;
    line-height: 50px;
    padding: 0 20px;
    color: white;
    font-size: 22px;
    white-space: nowrap;
  }

  .section {
    padding-top: 200px;
  }

  .feature {
    padding-top: 32px;
  }

  .feature > h3 {
    font-size: 70px;
  }

  .details > h2 {
    margin: 60px 0 30px 0;
    font-size: 50px;
  }

  .details > ul > li {
    font-size: 18px;
  }

  .img-left {
    padding: 0 7% 0 10%;
    justify-content: center;
  }

  .img-right {
    padding: 0 10% 0 7%;
    justify-content: center;
  }

  .section-img {
    max-width: 260px;
    flex:1;
    /* margin: 0 15px; */
  }

  .section-img > div{
    /* margin: 30px 0; */
    /* box-shadow: 0 0 10px 5px #ccc; */
  }

  .section-4 {
    padding-bottom: 200px;
  }

  .footer {
    padding: 0 200px;
  }

  .copy {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .copy > ul {
    padding: 30px 0 0;
  }

  .m1-1 {
    margin-top: 30px;
  }

  .m1-2 {
    margin-top: 90px;
  }

  .m4-2 {
    margin-top: 96px;
  }
  .m4-3 {
    margin-top: 20px;
  }
}
