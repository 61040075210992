* {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans KR', sans-serif;
}
body {
  overflow-x: hidden;
  overflow-y:scroll;
  background: #f5f5f5;
}
img{
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .only-pc {
    display: none !important;
  }
}
.box {
  box-sizing: border-box;
  align-items: center;
  min-height: 60vh;
  overflow: hidden;
}
.hor-fit {
  min-width: 100vh;
  box-sizing: border-box;
}
.ver-fit {
  min-height: 100vh;
  box-sizing: border-box;
}
.flexible {
  display: flex;
}
.flex-center {
  align-items: center;
}
.flex-1 {
  flex: 1 0;
}
.flex-2 {
  flex: 2 0;
}
.flex-3 {
  flex: 3 0;
}
.flex-4{
  flex: 4 0;
}
.main {
  padding: 0 40px;
  background: rgba(0, 0, 0, 0.3);
  /* backdrop-filter: blur(10px); */
}
.blur {
  background-image: url('rse/group.png');
  background-size: cover;
  background-position: center;
}
.intro {
  padding: 0 40px;
  background: #fff;
  text-align: center;
}
.service {
  background: #24243a;
}
.main-details {
  color: #fff;
}
.main-details > h2 {
  font-family: 'Noto Serif KR', sans-serif;
  font-size: 44px;
  font-weight: 300;
  line-height: 1.14;
}

.main-details > h1 {
  font-family: 'Noto Serif KR', sans-serif;
  font-weight: bold;
  font-size: 44px;
}

.main-details > p {
  font-size: 16px;
  line-height: 1.8;
}

.buttons {
  flex-direction: column;
  margin: 42px 0;
}

.button {
  width: 170px;
  line-height: 54px;
  border: 1px solid #fff;
  border-radius: 11px;
  margin: 8px;
  font-size: 15px;
  color: white;
  font-family: 'Poppins';
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;
}

.button > img{
  display:inline;
  width: 30px;
  height: 30px;
  margin-right: 12px;
}

.googleplay, .appstore {
  position: relative;
  z-index: 100;
  text-decoration: none;
}

.googleplay > img{
  width: 31px;
  height: 33px;
}

.appstore > img{
  width: 29px;
  height: 33px;
}

.intro > h3 {
  font-family: 'Noto Serif KR', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 2.31;
  color: #222;
  padding: 60px 0 0;
}

.intro > h2 {
  font-size: 34px;
  font-weight: 300;
  color: #000;
  padding: 30px 0;
  line-height: 1.4;
}

.intro > p {
  font-size: 15px;
  line-height: 1.55;
  color: #666;
}

.intro > img{
  width: 80%;
  max-width: 512px;
  padding: 70px 0;
}

.image-wrapper{
  margin: 50px 0 90px;
}

.service > h2 {
  width: 150px;
  font-family: 'Poppins';
  font-size: 44px;
  font-weight: 200;
  line-height: 0.93;
  color: #7c7c91;
  padding: 70px 0 0 28px;
}

.tip {
  width: 150px;
  height: 100px;
  border-left: 2px solid rgba(255, 255, 255, 0.2);
}

.tip > div {
  border-left: 4px solid #ba9365;
  margin: 0 -3px;
  height: 35px;
  overflow: visible;
}

.tip > div > p {
  line-height: 35px;
  padding: 0 10px;
  color: white;
  font-size: 15px;
}

.tip.right {
  text-align: right;
  border-left: none;
  border-right: 2px solid rgba(255, 255, 255, 0.2);
}

.tip.right > div {
  border-left: none;
  border-right: 4px solid #ba9365;
}

.pager-wrap {
  margin: 0 -28px;
}

.section {
  padding: 0 28px;
}
.lastsection{
  margin-bottom: 60px;
}

.feature {
  padding: 80px 0 0;
  display: flex;
  align-items: flex-end;
  margin: 0 -28px;
}

.feature > h3 {
  color: #b7b7be;
  font-family: 'Poppins';
  font-size: 44px;
  font-weight: 200;
  padding: 0 28px;
  line-height: 30px;
}

.feature > .hr {
  flex: 1;
  /* margin: 0 -20px; */
  border-bottom: solid 1px #c9c9c9;
}

.section h2 {
  font-size: 34px;
  font-weight: 300;
  line-height: 1.32;
}

.section ul {
  list-style: none;
  margin-left: 16px;
}

.section li {
  font-size: 15px;
  padding: 10px 0;
  line-height: 1.4;
}

.section li::before {
  content: '\2022';
  color: #b5ad9f;
  font-weight: bold;
  display: inline-block;
  width: 16px;
  margin-left: -16px;
  text-align: left;
}

.details {
  padding-right: 20px;
}

.details.right {
  padding-right: 0;
  padding-left: 20px;
  text-align: right;
}

.details > h2 {
  padding: 20px 0;
}

.footer {
  background: #fff;
}
.copy {
  display: flex;
  flex-direction: column;
}
.copy > p {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  padding: 30px 30px 0;
}
.copy > ul {
  list-style: none;
  padding: 18px 30px 0;
  font-size:14px;
}
.copy > ul > li {
  float: left;
}
.copy > ul > li:not(:first-of-type)::before {
  content: '｜';
}
.footer > p {
  font-size: 14px;
  color: #666;
  padding: 25px 30px;
  line-height: 1.4;
}



.a-hs{
  transition: all .3s;
}
.a-hs:hover{
  transform: scale(1.03);
}

.card {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  padding:17px;
  box-sizing: border-box;
}

.cardinner{
  padding-bottom: 178%;
  background: #fff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 2px 10px 0 #c9c9c9;
  height:0;
  /* background-image: url("rse/m1-1.png"); */
}

.cardinner img{
  /* border-radius: 16px; */
  position: relative;
  /* padding-bottom: 100%; */
  /* height: 0; */
}

.testwrapper {
  padding: 0 10px;
}

/* .slickwrapper > .slick-slider{

  margin:0 -15px;

} */

/* .slick-initialized .slick-slide {
  margin: 0 10px;

}

.slick-list {
  padding: 0 -50px;
} */


.slider {
  position:relative;
}

.slick-list {
  overflow: visible !important;
}

.slickwrapper {
  margin-top: 40px;
}

.slick-arrow {
  z-index: 999999999999999999999999999999999999999;
}

.slick-prev,
.slick-next{
  width: 40px !important;
  height: 80px !important;
  background: rgba(183, 183, 190, .7) !important;
  top: 50% !important;
  position: absolute !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.slick-prev {
  border-radius: 0px 50px 50px 0px !important;
  left: -29px !important;
}
.slick-next {
  border-radius: 50px 0 0 50px !important;
  right: -29px !important;
}
.slick-prev::before,
.slick-next::before {
  display:none !important;
}
.slick-prev::after,
.slick-next::after {
  content: '';
  display:block;
  background-size:contain;
  width:20px;
  height: 20px;
  position: absolute;
}
.slick-prev::after {
  left:5px;
  background-image:url(./res/arrow-left.svg);
}
.slick-next::after  {
  right:5px;
  background-image:url(./res/arrow-right.svg);
}